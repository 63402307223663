import React, { useState, useEffect } from "react";
import {
  contractAddress,
  abi,
  X3contract,
  X3abi,
} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { API } from "../../Utils/Api";
import axios from "axios";

const DirectIncomeReport = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address

  const LoginId =
    sessionStorage.getItem("UserId").toString() != null
      ? parseInt(sessionStorage.getItem("UserId").toString())
      : 0; //myState.address

  const [userid, setuserid] = useState(LoginId);

  // const ContractDataBind = async () => {
  //   const web3 = new Web3(window.ethereum);
  //   let contract = new window.web3.eth.Contract(abi, contractAddress);
  //   let a = await contract.methods.users(LoginAddress).call();
  //   let firstid = parseInt(a.id);
  //   setuserid(parseInt(firstid));
  // };

  // useEffect(() => {
  //   ContractDataBind();
  // }, []);

  const [DirectTeamData, setDirectTeamData] = useState([]);

  const referral_API = async () => {
    try {
      let responceRight = await axios.post(
        "https://phoenixmatrix-api.nakshtech.info/DirectIncome",
        {
          uid: userid,
          mtype: "0",
        }
        // ,
        // {
        //   headers: {
        //     Authorization: jwtToken,
        //   },
        // }
      );

      responceRight = responceRight.data.data[0];
      console.log("responceRight--->DirectIncome", responceRight);
      setDirectTeamData([]);
      let arr = [];
      responceRight.forEach((item, index) => {
        arr.push({
          RowNo: item.RowNumber,
          uid: item.uid,
          userAddress: item.toAddress,
          sid: item.fromid,
          refAddress: item.fromAddress,
          Income: item.amount,
          mtype: item.mtype,
          edate: item.edate1,
        });
      });
      setDirectTeamData(arr);
    } catch (error) { }
  };

  useEffect(() => {
    referral_API();
  }, [userid]);

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card partnrPdd">
                <div className="card-body">
                  <h4 className="card-title colow mb-4">Direct Income</h4>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="" style={{ color: "#000" }}>Sr.No.</th>
                          <th className="" style={{ color: "#000" }}>User Id</th>
                          <th className="" style={{ color: "#000" }}>User Address</th>
                          <th className="" style={{ color: "#000" }}>From Id</th>
                          <th className="" style={{ color: "#000" }}>From Address</th>
                          <th className="" style={{ color: "#000" }}>Income</th>
                          <th className="" style={{ color: "#000" }}>Mtype</th>
                          <th className="" style={{ color: "#000" }}>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {DirectTeamData &&
                          DirectTeamData.map((item, i) => (
                            <tr>
                              <td style={{ color: "#000" }}>{item.RowNo}</td>
                              <td style={{ color: "#000" }}>{item.uid}</td>
                              <td style={{ color: "#000" }}>
                                {item.userAddress.slice(0, 4) +
                                  "..." +
                                  item.userAddress.slice(38, 42)}
                              </td>
                              <td style={{ color: "#000" }}>{item.sid}</td>
                              <td style={{ color: "#000" }}>
                                {item.refAddress.slice(0, 4) +
                                  "..." +
                                  item.refAddress.slice(38, 42)}
                              </td>
                              <td style={{ color: "#000" }}>{item.Income}</td>
                              <td style={{ color: "#000" }}>{item.mtype}</td>
                              <td style={{ color: "#000" }}>{item.edate}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {/* end table-responsive */}
                  {/* <div className="pagination-buttons" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <button className="colow" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Previous
                    </button>
                    <button className="colow" onClick={handleNextPage} disabled={indexOfLastRow >= data.length}>
                      Next
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectIncomeReport;
