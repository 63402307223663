const initialStateaddress = 'Connect to wallet';


const Usermatamaskaddress = (state = initialStateaddress, action) => {
    switch(action.type){
        case "useraddress": return{ address: action.payload}
        default : return state;

    }
}
const Usermatamaskshortaddress = (state = initialStateaddress, action) => {
    switch(action.type){
        case "useraddressinshort": return{ address: action.payload}
        default : return state;

    }
}

const totalteamsize = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalteamsize": return{ teamsize: action.payload}
        default : return state;

    }
}
const totalteamsize1 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalteamsize1": return{ teamsize: action.payload}
        default : return state;

    }
}
const totalteamsize_slot3 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalteamsize_slot3": return{ teamsize: action.payload}
        default : return state;

    }
}
const totalteamsize_slot4 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalteamsize_slot4": return{ teamsize: action.payload}
        default : return state;

    }
}
const totalteamsize_slot5 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalteamsize_slot5": return{ teamsize: action.payload}
        default : return state;

    }
}
const totalteamsize_slot6 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalteamsize_slot6": return{ teamsize: action.payload}
        default : return state;

    }
}

//..............................................................................................
const totalrevnew = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalrevnew": return{ amount: action.payload}
        default : return state;

    }
}
const totalrevnew_slot2 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalrevnew_slot2": return{ amount: action.payload}
        default : return state;

    }
}
const totalrevnew_slot3 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalrevnew_slot3": return{ amount: action.payload}
        default : return state;

    }
}
const totalrevnew_slot4 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalrevnew_slot4": return{ amount: action.payload}
        default : return state;

    }
}
const totalrevnew_slot5 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalrevnew_slot5": return{ amount: action.payload}
        default : return state;

    }
}
const totalrevnew_slot6 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "totalrevnew_slot6": return{ amount: action.payload}
        default : return state;

    }
}

//..................................................................................................

const Total_slot_revenue_slot2 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "Total_slot_revenue_slot2": return{ amount: action.payload}
        default : return state;

    }
}
const Total_slot_revenue_slot3 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "Total_slot_revenue_slot3": return{ amount: action.payload}
        default : return state;

    }
}
const Total_slot_revenue_slot4 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "Total_slot_revenue_slot4": return{ amount: action.payload}
        default : return state;

    }
}
const Total_slot_revenue_slot5 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "Total_slot_revenue_slot5": return{ amount: action.payload}
        default : return state;

    }
}
const Total_slot_revenue_slot6 = (state = initialStateaddress, action) => {
    switch(action.type){
        case "Total_slot_revenue_slot6": return{ amount: action.payload}
        default : return state;

    }
}


const MainID = (state = initialStateaddress, action) => {
    switch(action.type){
        case "MainID": return{ amount: action.payload}
        default : return state;

    }
}



export  {MainID,totalteamsize_slot5,totalteamsize_slot6,totalrevnew_slot5,totalrevnew_slot6,Total_slot_revenue_slot6,Total_slot_revenue_slot5,totalteamsize_slot4,Total_slot_revenue_slot4,totalrevnew_slot4,Total_slot_revenue_slot3,totalrevnew_slot3,totalteamsize_slot3,Total_slot_revenue_slot2,totalrevnew_slot2,Usermatamaskaddress,Usermatamaskshortaddress,totalteamsize,totalteamsize1,totalrevnew};