import React, { useState, useEffect } from "react";
import usdt from "../../assets/usdt.png";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { contractAddress, abi } from "../../connectivity/api";
import { useSelector } from "react-redux";
import { GiRecycle } from "react-icons/gi";
import { FaArrowUpLong } from "react-icons/fa6";
import axios from "axios";
import { API } from "../../Utils/Api";
import { loadWeb3 } from "../../connectivity/api";
import {
  tokencontractAddress,
  tokenabi,
} from "../../connectivity/api";
import { ToastContainer, toast } from "react-toastify";

const X3Slot12 = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  let myteam = useSelector((state) => state.totalteamsize);
  let thisslotincome = useSelector((state) => state.totalrevnew);
  let User_MainID = useSelector((state) => state.MainID);
  const [userteam, setuserteam] = useState(0);
  const [renewslot1, setrenewslot1] = useState(0);
  // const [addresses, setAddresses] = useState([]);
  const [usertotalrenew, setusertotalrenew] = useState("0");
  const [info1, setinfo1] = useState([]);
  const [allcontrol, setallcontrol] = useState(0);
  const [recycleIDs, setRecycleIDs] = useState([]);
  const [uid, setuid] = useState(0);
  const [treeData, settreeData] = useState();


  console.log("treedata", treeData);

  console.log("treedata uid", treeData !== undefined ? treeData[0].uid : "", treeData !== undefined ? treeData[1].uid : "",
    treeData !== undefined ? treeData[2].uid : "", treeData !== undefined ? treeData[3].uid : "",
    treeData !== undefined ? treeData[4].uid : ""
    , treeData !== undefined ? treeData[5].uid : "", treeData !== undefined ? treeData[6].uid : ""
  )

  // if(treeData !== undefined)
  // {
  // console.log("treedata uid",treeData[0].uid,treeData[1].uid,treeData[2].uid,treeData[3].uid,treeData[4].uid
  //   ,treeData[5].uid,treeData[6].uid
  // );
  // }

  const [addresses, setAddresses] = useState({
    id: "",
    parent: "",
    levelOne: [],
    levelTwo: [],
  });

  //console.log("LoginAddress====>", LoginAddress);
  // console.log("addresses==>", addresses);

  let slot = 12;
  let slotAmount = 20480;
  let slotAmountExtra = 20980;

  const userinfo = async () => {
    try {
      // console.log("User_MainID", User_MainID, LoginAddress);
      let intex1;
      let totalslotrevenue;
      let a;
      let aa;
      let formattedAddresses = [];

      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        // console.log("users LoginAddress ==>", LoginAddress);
        let { id } = await contract.methods.users(LoginAddress).call();
        let userdata = await contract.methods.users(LoginAddress).call();

        //console.log("users==>", userdata);
        const {
          0: addressZero,
          1: arrayOne,
          2: arrayTwo,
          3: boolThree,
          4: addressFour,
        } = await contract.methods.usersa2Matrix(LoginAddress, slot).call();
        setuserteam(parseInt(arrayOne.length));

        // console.log("arrayOne", arrayOne);

        let arrayOneArray = [];
        for (let item of arrayOne) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayOneArray.push(id);
        }

        let arrayTwoArray = [];
        for (let item of arrayTwo) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayTwoArray.push(id);
        }

        let treeid = id === undefined ? "" : parseInt(id);

        setAddresses({
          id: treeid,
          parent:
            addressZero === "0x0000000000000000000000000000000000000000"
              ? ""
              : addressZero,
          levelOne: arrayOneArray,
          levelTwo: arrayTwoArray,
        });

        const { 0: recycleId, 1: recyclePosition_ } = await contract.methods
          .getPosition(LoginAddress, slot)
          .call();
        setrenewslot1(parseInt(recycleId));

        // const { 0: recycleId1, 1: recyclePosition1_ } = await contract.methods
        // .getPosition(LoginAddress, slot)
        // .call();
        // console.log(recycleId, "recycleId: ");
      }
    } catch (error) {
      console.log("Tree_View_Child Error :", error);
    }
  };

  useEffect(() => {
    userinfo();
    // bindtreeData_Prev();
    // bindtreeData_New();

    bindTreeHistory();
  }, []);

  const bindtreeData_Prev = async (value) => {
    try {
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let { id } = await contract.methods.users(LoginAddress).call();

      let response = await API.post("/GetTreeData", {
        userAddress: LoginAddress,
        matrix: "1",
        level: "1",
        count: value,
      });
      response = response.data.data;
      // console.log("GetTreeData", response.data.data);

      let arrayOneArray = [];
      let arrayTwoArray = [];

      if (response.data.data.length >= 2) {
        arrayOneArray.push(response.data.data[0].uid);
        arrayOneArray.push(response.data.data[1].uid);
      }

      if (response.data.data.length >= 6) {
        arrayTwoArray.push(response.data.data[2].uid);
        arrayTwoArray.push(response.data.data[3].uid);
        arrayTwoArray.push(response.data.data[4].uid);
        arrayTwoArray.push(response.data.data[5].uid);
      }

      setAddresses({
        id: parseInt(id),
        parent: "",
        levelOne: arrayOneArray,
        levelTwo: arrayTwoArray,
      });
    } catch (error) {
      console.log("Error fetch getting bindtreeData ", error);
    }
  };

  const bindtreeData_Next = async (value) => {
    try {
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let { id } = await contract.methods.users(LoginAddress).call();

      let response = await API.post("/GetTreeData", {
        userAddress: LoginAddress,
        matrix: "1",
        level: "1",
        count: value,
      });
      response = response.data.data;
      // console.log("GetTreeData", response.data.data);

      let arrayOneArray = [];
      let arrayTwoArray = [];

      if (response.data.data.length >= 2) {
        arrayOneArray.push(response.data.data[0].uid);
        arrayOneArray.push(response.data.data[1].uid);
      }

      if (response.data.data.length >= 6) {
        arrayTwoArray.push(response.data.data[2].uid);
        arrayTwoArray.push(response.data.data[3].uid);
        arrayTwoArray.push(response.data.data[4].uid);
        arrayTwoArray.push(response.data.data[5].uid);
      }

      setAddresses({
        id: parseInt(id),
        parent: "",
        levelOne: arrayOneArray,
        levelTwo: arrayTwoArray,
      });
    } catch (error) {
      console.log("Error fetch getting bindtreeData ", error);
    }
  };

  const bindTreeHistory = async () => {
    try {
      let response = await API.post("/GetTreeDataReport_New", {
        userAddress: LoginAddress,
        SlotAmount: 0,
        MatrixType: "A3"
      });
      response = response.data.data;
      // console.log("response",response);
      setinfo1(response);
    } catch (error) { }
  };

  const bindtreeData_New = async (levelid) => {
    try {
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let { id } = await contract.methods.users(LoginAddress).call();
      setuid(id);
      let res = await API.post("/GetUserTreeData", {
        userAddress: levelid,
      });
      res = res.data.data;

      console.log("res", res);
    } catch (error) {
      console.log("Error fetch getting bindtreeData ", error);
    }
  };

  const indexxxss = async (hgqku) => {
    window.web3 = new Web3(window.ethereum);
    let contract = new window.web3.eth.Contract(abi, contractAddress);
    if (hgqku == 0) {
      let a = await contract.methods.users(LoginAddress).call();
      // let indexxessid = await contract.methods
      //   .check_useractive_id_List(LoginAddress, 1)
      //   .call();
      // userinfo(indexxessid[parseInt(a.index)]);
    } else {
      setallcontrol(hgqku);
      let a = await contract.methods.users(LoginAddress).call();
      // let indexxessid = await contract.methods
      //   .check_useractive_id_List(LoginAddress, 1)
      //   .call();
      // if (indexxessid.length > 1) {
      //   userinfo(
      //     parseInt(indexxessid[parseInt(a.index) - parseInt(allcontrol)])
      //   );
      // } else {
      //   userinfo(parseInt(indexxessid[parseInt(a.index)]));
      // }
    }
  };

  const indexxxss_next = async () => {
    window.web3 = new Web3(window.ethereum);
    let contract = new window.web3.eth.Contract(abi, contractAddress);
    let a = await contract.methods.users(LoginAddress).call();
    // let indexxessid = await contract.methods
    //   .check_useractive_id_List(LoginAddress, 1)
    //   .call();
    // userinfo(parseInt(indexxessid[parseInt(a.index)]));
  };

  // useEffect(() => {
  //   indexxxss(0);
  // }, [LoginAddress]);

  const bindTree_New = async () => {
    try {
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let { id } = await contract.methods.users(LoginAddress).call();
      // console.log("id", id);

      console.log("bind", parseInt(id), renewslot1);

      let response = await API.post("/GetUserTreeData_A3_New", {
        uid: parseInt(id),
        package: 12,
        cycle: 1, // parseInt(renewslot1),
      }
      );
      console.log("bindTree_New", response);
      response = response.data.data;
      console.log("bindTree_New", response);
      settreeData(response);
    } catch (error) { }
  };



  useEffect(() => {
    bindTree_New();
  }, []);


  const [btnDisable, setbtnDisable] = useState(false);
  const buyNewLevel = async () => {
    try {
      setbtnDisable(true);
      if (LoginAddress) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(abi, contractAddress);
        const tokenContract = new web3.eth.Contract(
          tokenabi,
          tokencontractAddress
        );

        // Verify contract initialization
        if (!contract || !tokenContract) {
          throw new Error("Contract or token contract initialization failed");
        }
        let address = await loadWeb3();
        if (address.toUpperCase() != LoginAddress.toUpperCase()) {
          toast.error(`Address doesnot Macthed !`);
          setbtnDisable(false);
          return;
        }
        const user = await contract.methods.users(address).call();
        const id = user.id;
        const balanceOf = await tokenContract.methods.balanceOf(address).call();
        const oneEtherInWei = web3.utils.toWei(slotAmountExtra.toString(), "ether");

        const isUserExists = await contract.methods
          .isUserExists(address)
          .call();
        if (parseInt(id) != 0) {
          if (isUserExists == true) {
            // Check if the user is not registered
            if (balanceOf >= oneEtherInWei) {
              await tokenContract.methods
                .approve(contractAddress, oneEtherInWei)
                .send({ from: address });

              toast.success("Token has been approved");

              let txnDataSlot=  await contract.methods.buyNewLevel(2, slot).send({ from: address });
              toast.success("Buy New Level has been successfully purchased");
              console.log("resData transactionHash", txnDataSlot.transactionHash);
              txnDataSlot = txnDataSlot.transactionHash;

              /// Buy new Packege API call
              let userAddress = address;
              let userData = await contract.methods.users(userAddress).call();
              let userid = userData.id;

              let uplineresult = await contract.methods.activeGoldInfos(userAddress, 1).call();
              let position = parseInt(uplineresult.position);
              let uplineId = parseInt(uplineresult.currentParent);
              let uplineAddress = await contract.methods.idToAddress(uplineId).call();

              let resultSponsor = await API.post("/GetsponsorId",
                {
                  useraddress: userAddress
                });

              let sponsorId = resultSponsor.data.data[0].uplineId;
              let sponsorAddress = resultSponsor.data.data[0].uplineAddress;

              let res = await API.post("/InsertAlllUsers", {
                uid: parseInt(userid),
                sid: parseInt(sponsorId),
                refAddress: sponsorAddress,
                userAddress: userAddress,
                uplineId: parseInt(uplineId),
                uplineAddress: uplineAddress,
                position: position,
                package: slot,
                type: "A3",
                txn: txnDataSlot
              });

              console.log("Registartion API", res.data.data);

            } else {
              toast.error("Insufficiant Balance!!");
              setbtnDisable(false);
            }
          } else {
            toast.error("User does not eligible for Buy Level");
            setbtnDisable(false);
          }
        } else {
          toast.error("User is not registered");
          setbtnDisable(false);
        }
      } else {
        toast.error("Session expired ! Please login again");
        window.location.href = "/Login";
      }
    } catch (error) {
      console.log("Buy Level Error:", error);
    }
  };


  return (
    <div>
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="row">
            <div className="contentt">
              <span>Slot {slot}</span>
            </div>
          </div>
          <div className="row pb-1">
            <div className="pb-2">
              <div className=" pb-3">
                <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                  <div className="row">
                    <div className="PDev">
                      <p>Slot {slot}</p>
                      <div>
                        <img src={usdt} alt="usdt" className="usdtCoin" />
                        <b>20480</b>
                      </div>
                    </div>
                  </div>
                  <div className="TreeImgMian ">
                    <ul class="tree vertical">
                      <li className="kzsxjka">
                        <div class="popover__wrapper">
                          <a>
                            <h2 class="popover__title">
                              <div
                                _ngcontent-cgm-c25=""
                                className={
                                  treeData !== undefined ? treeData[0].uid : ""
                                    ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                    : "bg-white-200 h-5 rounded-full w-5"
                                }
                              >
                                {treeData !== undefined ? treeData[0].uid : ""}
                              </div>
                            </h2>
                          </a>
                        </div>
                        <ul>
                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      treeData !== undefined ? treeData[1].uid : ""
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {treeData !== undefined ? treeData[1].uid : ""}
                                  </div>
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined ? treeData[3].uid : ""
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData[3].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[7].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[7].uid : ""}
                                          </div>
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li style={{ display: "none" }}>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[14]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[14]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[8].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[8].uid : ""}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li style={{ display: "none" }}>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[5]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[5]}
                                      </div>

                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[16]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[16]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[17]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[17]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[18]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[18]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined ? treeData[4].uid : ""
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData[4].uid : ""}
                                      </div>

                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[9].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[9].uid : ""}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li style={{ display: "none" }}>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[20]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[20]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[10].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[10].uid : ""}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li style={{ display: "none" }}>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    class={
                                      addresses[2]
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {addresses[2]}
                                  </div>
                                  {addresses[2] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[2])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[7]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[7]}
                                      </div>
                                      {addresses[7] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[7])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[22]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[22]}
                                          </div>
                                          {addresses[22] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[22])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[23]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[23]}
                                          </div>
                                          {addresses[23] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[23])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[24]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[24]}
                                          </div>
                                          {addresses[24] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[24])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[8]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[8]}
                                      </div>
                                      {addresses[8] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[8])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[25]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[25]}
                                          </div>
                                          {addresses[25] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[25])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[26]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[26]}
                                          </div>
                                          {addresses[26] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[26])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[27]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[27]}
                                          </div>
                                          {addresses[27] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[27])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[9]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[9]}
                                      </div>
                                      {addresses[9] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[9])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[28]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[28]}
                                          </div>
                                          {addresses[28] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[28])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[29]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[29]}
                                          </div>
                                          {addresses[29] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[29])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[30]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[30]}
                                          </div>
                                          {addresses[30] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[30])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      treeData !== undefined ? treeData[2].uid : ""
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {treeData !== undefined ? treeData[2].uid : ""}
                                  </div>
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined ? treeData[5].uid : ""
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData[5].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[11].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[11].uid : ""}
                                          </div>
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li style={{ display: "none" }}>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[32]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[32]}
                                          </div>
                                          {addresses[32] ? (
                                            recycleIDs.includes(
                                              parseInt(addresses[32])
                                            ) ? (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <GiRecycle className="recycllee" />
                                              </div>
                                            ) : (
                                              <div className="bg-main-blue rounded-full w-5 h-5">
                                                <FaArrowUpLong className="recycllee" />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[12].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[12].uid : ""}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li style={{ display: "none" }}>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[11]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[11]}
                                      </div>

                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[34]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[34]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[35]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[35]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[36]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[36]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined ? treeData[6].uid : ""
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData[6].uid : ""}
                                      </div>

                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[13].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[13].uid : ""}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li style={{ display: "none" }}>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[38]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {addresses[38]}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            className={
                                              treeData !== undefined ? treeData[14].uid : ""
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >
                                            {treeData !== undefined ? treeData[14].uid : ""}
                                          </div>

                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="PDev">
                      <div className="d-flex">
                        <div className="me-4">
                          <p className="mb-0 pb-0">Members</p>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{userteam}</b>
                        </div>
                        <div>
                          <p className="mb-0 pb-0">Cycle</p>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                          <b>{renewslot1}</b>
                        </div>
                      </div>

                      <div>
                        <p className="mb-0 pb-0">Total slot revenue</p>
                        <svg
                          _ngcontent-onl-c25=""
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="#2CFF4E"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 stroke-current text-white-500"
                        >
                          <path
                            _ngcontent-onl-c25=""
                            clip-rule="evenodd"
                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                          ></path>
                        </svg>
                        <b>{usertotalrenew} USDT</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row akhriPutti lksnflf">
            <div className="kdsjflke">
              <Link to="/Dashboard/X3Slot11">
                <span>
                  <GrLinkPrevious /> Prev
                </span>
              </Link>
              <span>Slot: 12</span>
              <Link to="/Dashboard/X3">
                <span>
                  <GrLinkNext />
                </span>
              </Link>
            </div>
          </div>
          <div className="row akhriPutti lksnflf mt-4">
            <div className="kdsjflke">
              <Link>
                <span onClick={() => indexxxss(1)}>
                  <GrLinkPrevious /> Prev
                </span>
              </Link>
              <span>Recycle</span>
              <Link>
                <span onClick={() => indexxxss_next()}>
                  Next <GrLinkNext />
                </span>
              </Link>
            </div>
          </div>
          <div className="row akhriPutti mt-4">
            <div className="kdsjflkerre">
              <button
                className="btn btn-primary waves-effect waves-light pre btn-sm"
                onClick={() => buyNewLevel()}
                disabled={btnDisable}
              >
                Buy Slot
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive lksnflf">
        <table className="table align-middle table-nowrap mb-0">
          <thead className="table-light tabelqq">
            <tr>
              <th>S.No</th>
              <th>Amount</th>
              <th>Time</th>
              {/* <th className="align-middle">Slot</th> */}
              <th>Upline ID</th>
              <th>Matrix ID</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {info1.map((user, index) => (
              <tr key={index}>
                <td className="align-middle">{index + 1}</td>
                <td className="align-middle">{user.amount}</td>
                <td className="align-middle">{user.edate}</td>
                {/* <td className="align-middle"> </td> */}
                <td className="align-middle">{user.fromid} </td>
                <td className="align-middle"> {user.uid} </td>
                <td className="align-middle"> {user.fromAddress.slice(0, 4) + "..." + user.fromAddress.slice(38, 42)} </td>
                {/* <td className="align-middle">{user.slot.toString()}</td>
                <td className="align-middle">{user.uplineID.toString()}</td>
                <td className="align-middle">{user.userId.toString()}</td>
                <td className="align-middle">{user.add}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default X3Slot12;
