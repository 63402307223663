import logo from "./logo.svg";
import "./App.css";
import Hero from "./Components/Hero/Hero";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Dashboard from "./Components/Dashboard/Dashboard";
import Partner from "./Components/Partner/Partner";
import Stats from "./Components/Stats/Stats";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';



function App() {
  return (
    <>
    <ToastContainer />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Dashboard/*" element={<Dashboard />} />
        
      </Routes>
    </>
  );
}

export default App;
