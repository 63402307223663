export const useraddress = (address) => {
    return{
        type: "useraddress",
        payload :address

    }
}
export const useraddressinshort = (address) => {
    return{
        type: "useraddressinshort",
        payload :address

    }
}
export const totalteamsize = (teamsize) => {
    return{
        type: "totalteamsize",
        payload :teamsize

    }
}
export const totalteamsize1 = (teamsize) => {
    return{
        type: "totalteamsize1",
        payload :teamsize
    }
}
export const totalteamsize_slot3 = (teamsize) => {
    return{
        type: "totalteamsize_slot3",
        payload :teamsize
    }
}
export const totalteamsize_slot4 = (teamsize) => {
    return{
        type: "totalteamsize_slot4",
        payload :teamsize
    }
}
export const totalteamsize_slot5 = (teamsize) => {
    return{
        type: "totalteamsize_slot5",
        payload :teamsize
    }
}
export const totalteamsize_slot6 = (teamsize) => {
    return{
        type: "totalteamsize_slot6",
        payload :teamsize
    }
}


//...........................................................................

export const totalrevnew = (amount) => {
    return{
        type: "totalrevnew",
        payload :amount
    }
}
export const totalrevnew_slot2 = (amount) => {
    return{
        type: "totalrevnew_slot2",
        payload :amount
    }
}
export const totalrevnew_slot3 = (amount) => {
    return{
        type: "totalrevnew_slot3",
        payload :amount
    }
}
export const totalrevnew_slot4 = (amount) => {
    return{
        type: "totalrevnew_slot4",
        payload :amount
    }
}
export const totalrevnew_slot5 = (amount) => {
    return{
        type: "totalrevnew_slot5",
        payload :amount
    }
}
export const totalrevnew_slot6 = (amount) => {
    return{
        type: "totalrevnew_slot6",
        payload :amount
    }
}

export const Total_slot_revenue_slot2 = (amount) => {
    return{
        type: "Total_slot_revenue_slot2",
        payload :amount
    }
}
export const Total_slot_revenue_slot3 = (amount) => {
    return{
        type: "Total_slot_revenue_slot3",
        payload :amount
    }
}
export const Total_slot_revenue_slot4 = (amount) => {
    return{
        type: "Total_slot_revenue_slot4",
        payload :amount
    }
}
export const Total_slot_revenue_slot5 = (amount) => {
    return{
        type: "Total_slot_revenue_slot5",
        payload :amount
    }
}
export const Total_slot_revenue_slot6 = (amount) => {
    return{
        type: "Total_slot_revenue_slot6",
        payload :amount
    }
}


export const MainID = (amount) => {
    return{
        type: "MainID",
        payload :amount
    }
}