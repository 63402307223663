import React, { useState, useEffect } from "react";
import usdt from "../../assets/usdt.png";
import {
  MainID,
  totalteamsize_slot6,
  Total_slot_revenue_slot6,
  totalrevnew_slot6,
  totalteamsize_slot5,
  Total_slot_revenue_slot5,
  totalrevnew_slot5,
  totalteamsize_slot4,
  Total_slot_revenue_slot4,
  totalrevnew_slot4,
  Total_slot_revenue_slot3,
  totalrevnew_slot3,
  totalteamsize_slot3,
  totalrevnew,
  totalteamsize1,
  totalrevnew_slot2,
  Total_slot_revenue_slot2,
} from "../../Actions";
import Web3 from "web3";
import { contractAddress, abi, loadWeb3 } from "../../connectivity/api";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/app.min.css";
import "../../assets/bootstrap.min.css";
import X3_Child from "./X3_Child";
const X3 = () => {
  const slot1 = [
    { slot: 1, _package: 5 },
  ];
  const slot2 = [
    { slot: 2, _package: 10 },
  ];
  const slot3 = [
    { slot: 3, _package: 20 },
  ];
  const slot4 = [
    { slot: 4, _package: 40 },
  ];
  const slot5 = [
    { slot: 5, _package: 80 },
  ];
  const slot6 = [
    { slot: 6, _package: 160 }
  ];

  const dispatch = useDispatch();
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  let myteam = useSelector((state) => state.totalteamsize);
  let User_MainID = useSelector((state) => state.MainID);
  // let myteam1 = useSelector((state) => state.totalteamsize1);

  // const [userteam, setuserteam] = useState(0);
  // const [renewslot1, setrenewslot1] = useState(0);
  const [renewslot2, setrenewslot2] = useState(0);
  const [renewslot3, setrenewslot3] = useState(0);
  const [renewslot4, setrenewslot4] = useState(0);
  const [renewslot5, setrenewslot5] = useState(0);
  const [renewslot6, setrenewslot6] = useState(0);
  const [amount, setamount] = useState("");
  const [addresses, setAddresses] = useState({
    parent: "",
    levelOne: [],
    levelTwo: [],
  });
  const [addresses1, setAddresses1] = useState([]);
  const [addresses2, setAddresses2] = useState([]);
  const [addresses3, setAddresses3] = useState([]);
  const [addresses4, setAddresses4] = useState([]);
  const [addresses5, setAddresses5] = useState([]);
  const [memberslot2, setmemberslot2] = useState("");
  const [memberslot3, setmemberslot3] = useState("");
  const [memberslot4, setmemberslot4] = useState("");
  const [memberslot5, setmemberslot5] = useState("");
  const [memberslot6, setmemberslot6] = useState("");
  useEffect(() => { }, []);

  const userinfo1 = async () => {
    try {
      let a;
      let aa;
      let intex1;
      let teamslot2 = 0;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        a = await contract.methods.Users(LoginAddress).call();

        teamslot2 = parseInt([parseInt(a.team)]) + parseInt(teamslot2);
        let activeid = await contract.methods
          .check_useractive_id_List(LoginAddress, 2)
          .call();
        if (activeid.length > 0) {
          for (let w = 0; w < activeid.length; w++) {
            let team = await contract.methods
              .User_Referral(parseInt(activeid[w]))
              .call();
            totalteam1 = parseInt(totalteam1) + parseInt(team.referrals);
          }
          activeid = parseInt(activeid[parseInt(a.index_slot2)]);
          aa = await contract.methods
            .Pool_check_Direct(parseInt(activeid))
            .call();
          formattedAddresses[0] = parseInt(a.firstid_slot2);
          intex1 = parseInt(aa.Id1);
          let intex2 = parseInt(aa.Id2);
          let intex3 = parseInt(aa.Id3);
          let index1address;
          let index2address;
          let index3address;
          if (intex1 > 0) {
            index1address = await contract.methods
              .Pool_check_Direct(parseInt(intex1))
              .call();
            formattedAddresses[1] = intex1;
            if (parseInt(index1address.Id1) > 0) {
              formattedAddresses[4] = parseInt(index1address.Id1);
            }
            if (parseInt(index1address.Id2) > 0) {
              formattedAddresses[5] = parseInt(index1address.Id2);
            }
            if (parseInt(index1address.Id3) > 0) {
              formattedAddresses[6] = parseInt(index1address.Id3);
            }
          }
          if (intex2 > 0) {
            index2address = await contract.methods
              .Pool_check_Direct(parseInt(intex2))
              .call();
            formattedAddresses[2] = intex2;
            if (parseInt(index2address.Id1) > 0) {
              formattedAddresses[7] = parseInt(index2address.Id1);
            }
            if (parseInt(index2address.Id2) > 0) {
              formattedAddresses[8] = parseInt(index2address.Id2);
            }
            if (parseInt(index2address.Id3) > 0) {
              formattedAddresses[9] = parseInt(index2address.Id3);
            }
          }
          if (intex3 > 0) {
            index3address = await contract.methods
              .Pool_check_Direct(parseInt(intex3))
              .call();
            formattedAddresses[3] = intex3;
            if (parseInt(index3address.Id1) > 0) {
              formattedAddresses[10] = parseInt(index3address.Id1);
            }
            if (parseInt(index3address.Id2) > 0) {
              formattedAddresses[11] = parseInt(index3address.Id2);
            }
            if (parseInt(index3address.Id3) > 0) {
              formattedAddresses[12] = parseInt(index3address.Id3);
            }
          }
          setmemberslot2(parseInt(totalteam1));
          let totalrenew = parseInt(parseInt(totalteam1) / 12);
          setrenewslot2(totalrenew);
          dispatch(totalteamsize1(parseInt(totalteam1)));
          dispatch(totalrevnew_slot2(totalrenew));
          dispatch(
            Total_slot_revenue_slot2(
              window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether")
            )
          );
        }
      }
      setAddresses1(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot2:", error);
    }
  };

  const userinfo_Slot3 = async () => {
    try {
      let a;
      let aa;
      let intex1;
      let aab = 0;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        aab = await contract.methods.Users(LoginAddress).call();
        a = await contract.methods.Users1(LoginAddress).call();
        let activeid = await contract.methods
          .check_useractive_id_List(LoginAddress, 3)
          .call();
        for (let w = 0; w < activeid.length; w++) {
          let team = await contract.methods
            .User_Referral(parseInt(activeid[w]))
            .call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals);
        }
        activeid = parseInt(activeid[parseInt(a.index_slot3)]);
        let intex2;
        let intex3;
        if (activeid > 0) {
          aa = await contract.methods
            .Pool_check_Direct(parseInt(activeid))
            .call();
          formattedAddresses[0] = parseInt(activeid);
          intex1 = parseInt(aa.Id1);
          intex2 = parseInt(aa.Id2);
          intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if (intex1 > 0) {
          index1address = await contract.methods
            .Pool_check_Direct(parseInt(intex1))
            .call();
          formattedAddresses[1] = intex1;
          if (parseInt(index1address.Id1) > 0) {
            formattedAddresses[4] = parseInt(index1address.Id1);
          }
          if (parseInt(index1address.Id2) > 0) {
            formattedAddresses[5] = parseInt(index1address.Id2);
          }
          if (parseInt(index1address.Id3) > 0) {
            formattedAddresses[6] = parseInt(index1address.Id3);
          }
        }
        if (intex2 > 0) {
          index2address = await contract.methods
            .Pool_check_Direct(parseInt(intex2))
            .call();
          formattedAddresses[2] = intex2;
          if (parseInt(index2address.Id1) > 0) {
            formattedAddresses[7] = parseInt(index2address.Id1);
          }
          if (parseInt(index2address.Id2) > 0) {
            formattedAddresses[8] = parseInt(index2address.Id2);
          }
          if (parseInt(index2address.Id3) > 0) {
            formattedAddresses[9] = parseInt(index2address.Id3);
          }
        }
        if (intex3 > 0) {
          index3address = await contract.methods
            .Pool_check_Direct(parseInt(intex3))
            .call();
          formattedAddresses[3] = intex3;
          if (parseInt(index3address.Id1) > 0) {
            formattedAddresses[10] = parseInt(index3address.Id1);
          }
          if (parseInt(index3address.Id2) > 0) {
            formattedAddresses[11] = parseInt(index3address.Id2);
          }
          if (parseInt(index3address.Id3) > 0) {
            formattedAddresses[12] = parseInt(index3address.Id3);
          }
        }

        setmemberslot3(parseInt(totalteam1));
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot3(totalrenew);
        dispatch(totalteamsize_slot3(parseInt(totalteam1)));
        dispatch(totalrevnew_slot3(totalrenew));
        dispatch(
          Total_slot_revenue_slot3(
            window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether")
          )
        );
      }
      setAddresses2(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };

  const userinfo_Slot4 = async () => {
    try {
      let a;
      let aa;
      let intex1;
      let aab = 0;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        aab = await contract.methods.Users(LoginAddress).call();
        a = await contract.methods.Users1(LoginAddress).call();
        let activeid = await contract.methods
          .check_useractive_id_List(LoginAddress, 4)
          .call();
        for (let w = 0; w < activeid.length; w++) {
          let team = await contract.methods
            .User_Referral(parseInt(activeid[w]))
            .call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals);
        }
        activeid = parseInt(activeid[parseInt(a.index_slot4)]);
        let intex2;
        let intex3;
        if (activeid > 0) {
          aa = await contract.methods
            .Pool_check_Direct(parseInt(activeid))
            .call();
          formattedAddresses[0] = parseInt(activeid);
          intex1 = parseInt(aa.Id1);
          intex2 = parseInt(aa.Id2);
          intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if (intex1 > 0) {
          index1address = await contract.methods
            .Pool_check_Direct(parseInt(intex1))
            .call();
          formattedAddresses[1] = intex1;
          if (parseInt(index1address.Id1) > 0) {
            formattedAddresses[4] = parseInt(index1address.Id1);
          }
          if (parseInt(index1address.Id2) > 0) {
            formattedAddresses[5] = parseInt(index1address.Id2);
          }
          if (parseInt(index1address.Id3) > 0) {
            formattedAddresses[6] = parseInt(index1address.Id3);
          }
        }
        if (intex2 > 0) {
          index2address = await contract.methods
            .Pool_check_Direct(parseInt(intex2))
            .call();
          formattedAddresses[2] = intex2;
          if (parseInt(index2address.Id1) > 0) {
            formattedAddresses[7] = parseInt(index2address.Id1);
          }
          if (parseInt(index2address.Id2) > 0) {
            formattedAddresses[8] = parseInt(index2address.Id2);
          }
          if (parseInt(index2address.Id3) > 0) {
            formattedAddresses[9] = parseInt(index2address.Id3);
          }
        }
        if (intex3 > 0) {
          index3address = await contract.methods
            .Pool_check_Direct(parseInt(intex3))
            .call();
          formattedAddresses[3] = intex3;
          if (parseInt(index3address.Id1) > 0) {
            formattedAddresses[10] = parseInt(index3address.Id1);
          }
          if (parseInt(index3address.Id2) > 0) {
            formattedAddresses[11] = parseInt(index3address.Id2);
          }
          if (parseInt(index3address.Id3) > 0) {
            formattedAddresses[12] = parseInt(index3address.Id3);
          }
        }

        setmemberslot4(parseInt(totalteam1));
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot4(totalrenew);
        dispatch(totalteamsize_slot4(parseInt(totalteam1)));
        dispatch(totalrevnew_slot4(totalrenew));
        dispatch(
          Total_slot_revenue_slot4(
            window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether")
          )
        );
      }
      setAddresses3(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };

  const userinfo_Slot5 = async () => {
    try {
      let a;
      let aa;
      let intex1;
      let aab = 0;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        aab = await contract.methods.Users(LoginAddress).call();
        a = await contract.methods.Users1(LoginAddress).call();
        let activeid = await contract.methods
          .check_useractive_id_List(LoginAddress, 5)
          .call();
        for (let w = 0; w < activeid.length; w++) {
          let team = await contract.methods
            .User_Referral(parseInt(activeid[w]))
            .call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals);
        }
        activeid = parseInt(activeid[parseInt(a.index_slot5)]);
        let intex2;
        let intex3;
        if (activeid > 0) {
          aa = await contract.methods
            .Pool_check_Direct(parseInt(activeid))
            .call();
          formattedAddresses[0] = parseInt(activeid);
          intex1 = parseInt(aa.Id1);
          intex2 = parseInt(aa.Id2);
          intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if (intex1 > 0) {
          index1address = await contract.methods
            .Pool_check_Direct(parseInt(intex1))
            .call();
          formattedAddresses[1] = intex1;
          if (parseInt(index1address.Id1) > 0) {
            formattedAddresses[4] = parseInt(index1address.Id1);
          }
          if (parseInt(index1address.Id2) > 0) {
            formattedAddresses[5] = parseInt(index1address.Id2);
          }
          if (parseInt(index1address.Id3) > 0) {
            formattedAddresses[6] = parseInt(index1address.Id3);
          }
        }
        if (intex2 > 0) {
          index2address = await contract.methods
            .Pool_check_Direct(parseInt(intex2))
            .call();
          formattedAddresses[2] = intex2;
          if (parseInt(index2address.Id1) > 0) {
            formattedAddresses[7] = parseInt(index2address.Id1);
          }
          if (parseInt(index2address.Id2) > 0) {
            formattedAddresses[8] = parseInt(index2address.Id2);
          }
          if (parseInt(index2address.Id3) > 0) {
            formattedAddresses[9] = parseInt(index2address.Id3);
          }
        }
        if (intex3 > 0) {
          index3address = await contract.methods
            .Pool_check_Direct(parseInt(intex3))
            .call();
          formattedAddresses[3] = intex3;
          if (parseInt(index3address.Id1) > 0) {
            formattedAddresses[10] = parseInt(index3address.Id1);
          }
          if (parseInt(index3address.Id2) > 0) {
            formattedAddresses[11] = parseInt(index3address.Id2);
          }
          if (parseInt(index3address.Id3) > 0) {
            formattedAddresses[12] = parseInt(index3address.Id3);
          }
        }

        setmemberslot5(parseInt(totalteam1));
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot5(totalrenew);
        dispatch(totalteamsize_slot5(parseInt(totalteam1)));
        dispatch(totalrevnew_slot5(totalrenew));
        dispatch(
          Total_slot_revenue_slot5(
            window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether")
          )
        );
      }
      setAddresses4(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };

  const userinfo_Slot6 = async () => {
    try {
      let a;
      let aa;
      let intex1;
      let aab = 0;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        aab = await contract.methods.Users(LoginAddress).call();
        a = await contract.methods.Users1(LoginAddress).call();
        let activeid = await contract.methods
          .check_useractive_id_List(LoginAddress, 6)
          .call();
        for (let w = 0; w < activeid.length; w++) {
          let team = await contract.methods
            .User_Referral(parseInt(activeid[w]))
            .call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals);
        }
        activeid = parseInt(activeid[parseInt(a.index_slot6)]);
        let intex2;
        let intex3;
        if (activeid > 0) {
          aa = await contract.methods
            .Pool_check_Direct(parseInt(activeid))
            .call();
          formattedAddresses[0] = parseInt(activeid);
          intex1 = parseInt(aa.Id1);
          intex2 = parseInt(aa.Id2);
          intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if (intex1 > 0) {
          index1address = await contract.methods
            .Pool_check_Direct(parseInt(intex1))
            .call();
          formattedAddresses[1] = intex1;
          if (parseInt(index1address.Id1) > 0) {
            formattedAddresses[4] = parseInt(index1address.Id1);
          }
          if (parseInt(index1address.Id2) > 0) {
            formattedAddresses[5] = parseInt(index1address.Id2);
          }
          if (parseInt(index1address.Id3) > 0) {
            formattedAddresses[6] = parseInt(index1address.Id3);
          }
        }
        if (intex2 > 0) {
          index2address = await contract.methods
            .Pool_check_Direct(parseInt(intex2))
            .call();
          formattedAddresses[2] = intex2;
          if (parseInt(index2address.Id1) > 0) {
            formattedAddresses[7] = parseInt(index2address.Id1);
          }
          if (parseInt(index2address.Id2) > 0) {
            formattedAddresses[8] = parseInt(index2address.Id2);
          }
          if (parseInt(index2address.Id3) > 0) {
            formattedAddresses[9] = parseInt(index2address.Id3);
          }
        }
        if (intex3 > 0) {
          index3address = await contract.methods
            .Pool_check_Direct(parseInt(intex3))
            .call();
          formattedAddresses[3] = intex3;
          if (parseInt(index3address.Id1) > 0) {
            formattedAddresses[10] = parseInt(index3address.Id1);
          }
          if (parseInt(index3address.Id2) > 0) {
            formattedAddresses[11] = parseInt(index3address.Id2);
          }
          if (parseInt(index3address.Id3) > 0) {
            formattedAddresses[12] = parseInt(index3address.Id3);
          }
        }

        setmemberslot6(parseInt(totalteam1));
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot6(totalrenew);
        dispatch(totalteamsize_slot6(parseInt(totalteam1)));
        dispatch(totalrevnew_slot6(totalrenew));
        dispatch(
          Total_slot_revenue_slot6(
            window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether")
          )
        );
      }
      setAddresses5(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };

  useEffect(() => {
    // userinfo();
    // userinfo1();
    // userinfo_Slot3();
    // userinfo_Slot4();
    // userinfo_Slot5();
    // userinfo_Slot6();
  }, [LoginAddress]);



  return (
    <div>
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="row">
            <div className="contentt">
              <span>Phoenix P15</span>
              <span>{amount} USDT</span>
            </div>
          </div>
          <div className="row pb-1">
            {slot1.map(({ slot, _package }) => {
              return <X3_Child slot={slot} _package={_package} />;
            })}
          </div>

          <div className="row pb-1">
            {slot2.map(({ slot, _package }) => {
              return <X3_Child slot={slot} _package={_package} />;
            })}
          </div>

          <div className="row pb-1">
            {slot3.map(({ slot, _package }) => {
              return <X3_Child slot={slot} _package={_package} />;
            })}
          </div>
          <div className="row pb-1">
            {slot4.map(({ slot, _package }) => {
              return <X3_Child slot={slot} _package={_package} />;
            })}
          </div>

          <div className="row pb-1">
            {slot5.map(({ slot, _package }) => {
              return <X3_Child slot={slot} _package={_package} />;
            })}
          </div>

          <div className="row pb-1">
            {slot6.map(({ slot, _package }) => {
              return <X3_Child slot={slot} _package={_package} />;
            })}
          </div>

        </div>
      </div>
    </div>
  );
};

export default X3;
