import {MainID,totalteamsize_slot5,totalteamsize_slot6,totalrevnew_slot5,totalrevnew_slot6,Total_slot_revenue_slot6,Total_slot_revenue_slot5,totalteamsize_slot4,Total_slot_revenue_slot4,totalrevnew_slot4,Total_slot_revenue_slot3,totalrevnew_slot3,totalteamsize_slot3,Total_slot_revenue_slot2,totalrevnew_slot2,totalrevnew,Usermatamaskshortaddress,Usermatamaskaddress,totalteamsize,totalteamsize1} from "./updown";

import {combineReducers} from "redux";

const rootReducer = combineReducers({
    
    Usermatamaskaddress,
    Usermatamaskshortaddress,
    totalteamsize,
    totalteamsize1,
    totalrevnew,
    totalrevnew_slot2,
    Total_slot_revenue_slot2,
    totalteamsize_slot3,
    totalrevnew_slot3,
    Total_slot_revenue_slot3,
    totalrevnew_slot4,
    Total_slot_revenue_slot4,
    totalteamsize_slot4,
    Total_slot_revenue_slot5,
    Total_slot_revenue_slot6,
    totalrevnew_slot5,
    totalrevnew_slot6,
    totalteamsize_slot5,
    totalteamsize_slot6,
    MainID
});

export default rootReducer;