import React, { useState, useEffect } from "react";
import {
  contractAddress,
  abi,
  X3contract,
  X3abi,
} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { API } from "../../Utils/Api";
import axios from "axios";

const P15_Income = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId =
    sessionStorage.getItem("UserId").toString() != null
      ? parseInt(sessionStorage.getItem("UserId").toString())
      : 0; //myState.address
  const [userid, setuserid] = useState(LoginId);

  const [DirectTeamData, setDirectTeamData] = useState([]);

  const referral_API = async () => {
    try {
      let responceRight = await API.post("/GetTreeDataReport_New", {
        userAddress: LoginAddress,
        SlotAmount: 0,
        MatrixType: "A3",
      });
      responceRight = responceRight.data.data;

      console.log("responceRight--->A3_Income", responceRight);
      setDirectTeamData([]);
      let arr = [];
      responceRight.forEach((item, index) => {
        arr.push({
          RowNo: item.RowNumber,
          uid: item.uid,
          userAddress: item.toAddress,
          amount: item.amount,
          fromid: item.fromid,
          fromAddress: item.fromAddress,
          edate: item.edate1,
          RowNo:item.RowNo
        });
      });
      setDirectTeamData(arr);
    } catch (error) {}
  };

  useEffect(() => {
    referral_API();
  }, [userid]);

  return (
    <div>
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card partnrPdd">
              <div className="card-body">
                <h4 className="card-title colow mb-4">P15 Income</h4>
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                      <th className="" style={{ color: "#000" }}>Sr.No.</th>
                          <th className="" style={{ color: "#000" }}>Income</th>
                          <th className="" style={{ color: "#000" }}>Time</th>
                          <th className="" style={{ color: "#000" }}>Upline ID</th>
                          <th className="" style={{ color: "#000" }}>Matrix ID</th>
                          <th className="" style={{ color: "#000" }}>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DirectTeamData &&
                        DirectTeamData.map((item, i) => (
                          <tr>
                              <td style={{ color: "#000" }}>{item.RowNo}</td>
                              <td style={{ color: "#000" }}>{item.amount}</td>
                              <td style={{ color: "#000" }}>{item.edate}</td>
                              <td style={{ color: "#000" }}>{item.fromid} </td>
                              <td style={{ color: "#000" }}> {item.uid} </td>
                              <td style={{ color: "#000" }}>
                                {item.userAddress.slice(0, 4) +
                                  "..." +
                                  item.userAddress.slice(38, 42)}
                              </td>
                            </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/* end table-responsive */}
                {/* <div className="pagination-buttons" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                  <button className="colow" onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                  </button>
                  <button className="colow" onClick={handleNextPage} disabled={indexOfLastRow >= data.length}>
                    Next
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default P15_Income;