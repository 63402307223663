import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import banner from "../../assets/banner2.png";
import "./hero.css"

const Hero = () => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 50000); 
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    console.log("Close button clicked"); 
    setShowPopup(false);
  };

  // useEffect(() => {
  //   var countDownDate = new Date("Monday Aug 5 2024 19:00:19");
  //   countDownDate.setHours(countDownDate.getHours());
  //   countDownDate.setMinutes(countDownDate.getMinutes());

  //   var x = setInterval(function () {
  //     var now = new Date().getTime();

  //     var distance = countDownDate - now;

  //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     var hours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     document.getElementById("demo").innerHTML =
  //       days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

  //     if (distance < 0) {
  //       clearInterval(x);
  //       document.getElementById("demo").innerHTML = "EXPIRED";
  //     }
  //   }, 1000);
  //   return () => clearInterval(x);
  // }, []);

  return (
    <div>
      <>
  
   
        <Header />
        {/* {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <img src={banner} alt="Popup Image" />
            <button className="close-button" onClick={closePopup}>X</button>
          </div>
        </div>
      )} */}
       

        {/* Start -slider-area */}
        {/* ============================================================= */}
        <div className="slider-area d-flex align-items-center" id="home">
    
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="single-slider-box">
                  <div className="slider-content">
                    <div className="slider-title">
                      <h2 className="h2">PHOENIX MATRIXES</h2>
                      {/* <h1>RISING FROM THE ASHES </h1> */}
                      <p>
                        A decentralized networking platform based on smart
                        contracts technology, which brings
                        people together from all over the world and opens up
                        endless possibilities new economic financial systems
                      </p>
                    </div>
                  </div>
                  {/* <div className="slider-button">
                    <a href="#">Get Started Now</a>
                  </div> */}

                  <div className="slider-button mt-3">
                    <a href="./PHOENIX_MATRIXES.pdf" target="_blank">
                      Presentation
                    </a>
                  </div>

                  {/* <h3>Pre Sale Start Time</h3>
                      <div id="demo"></div>*/}

                </div>
                
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-sliders-box">
                  <div className="slider-thumb bounce-animate">
                    <img src="assets/images/logo.png" width="100%" />
                    {/* <div class="shaps_img rotateme">
							<img src="assets/images/crypto.png" alt="">
						</div>
						<div class="seps_img bounce-animate">
							<img src="assets/images/crypto-1.png" alt="">
						</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        <div className="data-table">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  {/* <div class="sub-title">
						<h3>market</h3>
					</div> */}
                  {/* <div className="main-title text-center">
                    <h1>
                      {" "}
                      Platform Recent{" "}
                      <span className="text-gradient">Activity</span>
                    </h1>
                    <p>
                      Real-time global events of the Phoenix Matrix Platform
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              {/*  <div class="col-lg-12 col-md-12">
          <div class="row align-items-center data-box">
              <div class="col-lg-4 col-md-4">
                  <div class="data-box">
                      <div class="data-content">
                          <h2>Curency</h2>
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-md-2">
                  <div class="change">
                      <div class="change-thumb">
                          <span>24H Change</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-md-2">
                  <div class="amrcat-cap-content">
                      <p>Market Cap</p>
                  </div>
              </div>
              <div class="col-lg-2 col-md-2">
                  <div class="tsupply-content">
                      <p>T Supply</p>
                  </div>
              </div>
              <div class="col-lg-2 col-md-2">
                  <div class="valume-content">
                      <p>24H Volume</p>
                  </div>
              </div>
          </div>
      </div> */}
            </div>
          </div>
        </div>
        {/* Start about-area */}
        {/*==================================================*/}
        <div className="about-area" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="section-title mt-4">
                    {/* <div class="sub-title">
							<h3>users</h3>
						</div> */}
                    <div className="main-title">
                      <h1>
                        What is{" "}
                        <span className="text-gradient"> Smart Contract </span>
                      </h1>
                    </div>
                    <div className="section-text">
                      <p>
                      Smart contracts are digital contracts stored on a blockchain that are automatically executed when predetermined terms and conditions are met.

<br>
</br>
Smart contracts are typically used to automate the execution of an agreement so that all participants can be immediately certain of the outcome, without any intermediary’s involvement or time loss. They can also automate a workflow, triggering the next action when predetermined conditions are met.


                      </p>
                    </div>
                  </div>
                  {/* <div className="slider-button mt-4">
                    <a href="#">Start Trade</a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="about-thumb1">
                    <img src="assets/images/mobile.png" alt="" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="about-thumb1">
                    <img src="assets/images/vision.png" alt="" width="90%" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="section-title mt-4">
                    <div className="main-title">
                      <h1>Vision </h1>
                    </div>
                    <div className="section-text">
                      <p>
                      
                         Our Mission to create huge commniuty through its matrix program then after that we are going to introduce our own <b>launchpad</b> there we are giving advance information about upcoming crypto currency programs 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="section-title mt-4">
                    <div className="main-title">
                      <h1>Mission </h1>
                    </div>
                    <div className="section-text">
                      <p>
                        Our mission is to reaching out maximum people/ community
                        who are seeking lifetime income opportunity through
                        safest/ affordable /easy &amp; simple decentralized
                        platform.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="about-thumb1">
                    <center>
                      <img src="assets/images/mission.png" className="media" />
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        {/* Start feature-area */}
        {/*==================================================*/}
        <div className="feature-area" id="Service" style={{ display: "none" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sections-title">
                  {/* <div class="sub-title">
						<h3>features</h3>
					</div> */}
                  <div className="main-title">
                    <h1>
                      World Class Trading <br /> Platform
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/1.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Exchange</h3>
                    <p>
                      {" "}
                      Join the Future of Finance. Log in to Your Phoenix Matrix
                      Account and Embark on Your Journey of Buying and Selling
                      Crypto currencies Today!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/2.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>High Performance</h3>
                    <p>
                      Seamless Trading." Trade with Confidence and Easy,
                      Supported by Cutting-Edge Software and Exceptional
                      Service.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/3.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Super Fast KYC</h3>
                    <p>
                      Lightning-Fast KYC. Experience Seamless Verification and
                      User-Friendly KYC Processes. Get Trading in Seconds!"{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/4.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Order Types</h3>
                    <p>
                      With a diverse array of order types at your fingertips,
                      tailored to your every trading need, experience the
                      ultimate freedom in crypto asset transactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/5.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Minimal Interface</h3>
                    <p>
                      Where Minimalism Meets Functionality. Our platform boasts
                      a sleek and minimal interface, designed for maximum user
                      efficiency{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/6.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Safe And Secure</h3>
                    <p>
                      We prioritize your security above all else. Our platform
                      employs top-notch security measures, guaranteeing the
                      safety of your transactions.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        <div className="feature-area" id="Roadmap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sections-title">
                  {/* <div class="sub-title">
                  <h3>features</h3>
              </div> */}
                  <div className="main-title">
                    <h1>Roadmap</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/5.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Last Quarter of 2024-</h3>
                    <p>
                      {" "}
                      Since we will have a big community we have a vision to get
                      benefited our community in several ways. We are launching
                      our own coin launcher for whole crypto industry. Since our
                      community will have first hand information they will get
                      prime benefit of these big crypto launches.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/2.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Third Quarter of 2024-</h3>
                    <p>
                      We are introducing our gaming portal in 3rd Quarter of
                      2024 and giving subscription to all our members as per
                      their slots value and making it to a legal license.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        <div
          className="testimonial-area"
          id="Roadmap"
          style={{ display: "none" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sections-title">
                  {/* <div class="sub-title">
						<h3>testimonial</h3>
					</div> */}
                  <div className="main-title">
                    <h1>Roadmap</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="owl-carousel testi_list">
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-8.png" alt="" />
                      <div className="testi-title">
                        <h2>alex john</h2>
                        <h4>founder</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(4.5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-9.png" alt="" />
                      <div className="testi-title">
                        <h2>Anna Asler</h2>
                        <h4>Investor</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-7.png" alt="" />
                      <div className="testi-title">
                        <h2>Debra Hiles</h2>
                        <h4>Students</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(4.5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-8.png" alt="" />
                      <div className="testi-title">
                        <h2>alex john</h2>
                        <h4>founder</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(4.5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-9.png" alt="" />
                      <div className="testi-title">
                        <h2>Anna Asler</h2>
                        <h4>Investor</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        {/* Start call-do-action-area */}
        <div className="about-area" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="section-title mt-4">
                    {/* <div class="sub-title">
							<h3>users</h3>
						</div> */}
                    <div className="main-title">
                      <h1>
                        What is Decentralized
                        <span className="text-gradient">
                          {" "}
                          Blockchain Network
                        </span>
                      </h1>
                    </div>
                    <div className="section-text">
                      <p>
                        In a decentralized blockchain network, no one has to
                        know or trust anyone else. Each member in the network
                        has a copy of the exact same data in the form of a
                        distributed ledger. If a member’s ledger is altered or
                        corrupted in any way, it will be rejected by the
                        majority of the members in the network.
                      </p>
                      <p>
                        Phoenix Matrixes are most powerful immortal BNB smart
                        contract running on most user friendly BNB Smart Chain.
                        This smart contract is different from other smart
                        contracts till date in terms of safest, surest and
                        fastest income with very affordable business model
                        starting with only $10 one time payment (fixed rate) in
                        world's Number third crypto currency BNB Coin.
                      </p>
                    </div>
                  </div>
                  {/* <div className="slider-button mt-4">
                    <a href="#">Start Trade</a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="about-thumb mt-5">
                    <img src="assets/images/logo.png" alt="" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        <div className="call-do-action-area dd_sec tbf_section">
          <div className="container">
            <div className="call-do-action-title text-center section-text ">
              <h1 className="mb-2">How it works</h1>
              <p className="text-black">
                Stacks is a production-ready library of stackable
                <br />
                content blocks built in React Native.
              </p>
            </div>
          </div>
          <div className="dd_cards">
            <div className="row">
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                  <div className="thumb d-flex justify-content-center">
                    <img
                      src="assets/images/meta.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span>Step 1</span>
                  <h4 className="">Create Wallet on Decentralized Wallet on metamask or trust wallet</h4>
                  {/* <p>Enter your email address and create a strong password.</p> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                  <div className="thumb d-flex justify-content-center">
                    <img
                      src="assets/images/dapp.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span>Step 2</span>
                  <h4>Place the Registration Link you get from your upline in dapp browser.</h4>
                  {/* <p>
                    Complete the two-factor authentication process (2FA). Wait
                    for your account to be verified and approved .
                  </p> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar">
                  <div className="thumb d-flex justify-content-center">
                    <img
                      src="assets/images/how_3.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span>Step 3</span>
                  <h4>Follow the smart contract call</h4>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-area" id="contact">
          <div className="community_sec">
            <div className="container">
              <div className="card bgsw">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <div className="section-title1 mb-0 text-start pb-0 no-border">
                        <h1 className="">Join Our Community </h1>
                        <p className=" mb-0 bt">
                          Phoenix Matrix is global. Join the conversation in any
                          of our worldwide communities.
                        </p>
                        <div className="d-flex  joc_social_row ">
                          <a
                            className="joc_social"
                            title=" Facebook"
                            target="_blank"
                            href="https://www.facebook.com/profile.php?id=61562340140391&mibextid=ZbWKwL"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                          {/* <a
                            className="joc_social"
                            title=" Twitter"
                            target="_blank"
                            href="#"
                          >
                            <i className="fab fa-twitter  ri-xl" />
                          </a> */}
                          <a
                            className="joc_social"
                            title=" Instagram"
                            target="_blank"
                            href="https://www.instagram.com/phoenixmatrixes?igsh=MWs0NHoxMWpsMXVuNg=="
                          >
                            <i className="fab fa-instagram  ri-xl" />
                          </a>
                          <a
                            className="joc_social"
                            title="Telegram"
                            target="_blank"
                            href="https://t.me/phoenixImmortalmatrixes"
                          >
                            <i className="fab fa-telegram ri-xl" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <img
                        src="assets/images/joc_img.svg"
                        alt=""
                        className="img-fluid joc_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        {/* Start footer-area */}
        {/*==================================================*/}
        <div className="footer-area">
          <div className="container">
            <div className="row tops" style={{display:"none"}}>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-logo">
                    <img src="assets/images/logo.png" width="150px" alt="" />
                  </div>
                  <div className="footer-content">
                   
                    <div className="footer-icon">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/profile.php?id=61562340140391&mibextid=ZbWKwL
"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="https://t.me/phoenixImmortalmatrixes">
                            <i className="fab fa-telegram" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/phoenixmatrixes?igsh=MWs0NHoxMWpsMXVuNg==
"
                          >
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-content">
                    <div className="footer-title">
                      <h2>Catagories</h2>
                    </div>
                    <div className="footer-ico">
                      <ul>
                        <li>
                          {/* <a href="index.html"> */}
                          <span>Home</span>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a href="#about"> */}
                          <span>About</span>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a href="#Service"> */}
                          <span>Service</span>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a href="#roadmap"> */}
                          <span>Roadmap</span>
                          {/* </a> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-content">
                    <div className="footer-title">
                      <h2>Useful Links</h2>
                    </div>
                    <div className="footer-ico">
                      <ul>
                        <li>
                          {/* <a href="#blog"> */}
                          <span>Blog</span>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a href="#contact"> */}
                          <span>Contact</span>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a href="#"> */}
                          <span>Login</span>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a href="#"> */}
                          <span>Register</span>
                          {/* </a> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-content">
                    <div className="footer-titles">
                      <h2>Newsletter</h2>
                      <p>
                        Get now free 20% discount for all products on your first
                        order
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form_box style-two">
                          <input
                            type="text"
                            name="youe email address"
                            placeholder="Enter E-mail"
                            className="text-white"
                          />
                          <button type="submit">
                            <i className="far fa-paper-plane" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row footer-bottom">
              <div className="col-lg-6 col-md-6">
                <div className="copy-left-box">
                  <div className="copy-left-title">
                    <h3>
                      © Cryptobit all Rights Reserved. By{" "}
                      <span>Phoenixmatrix.live</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="copy-right-box">
                  <div className="copy-right-title">
                    <ul>
                      <li>
                        <a href="#">
                          <span>Privacy Policy</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>Terms &amp; Condition</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        {/* Start Search Popup Area */}
        {/*==================================================*/}
        <div className="search-popup">
          <button className="close-search style-two">
            <i className="fa fa-times" />
          </button>
          <button className="close-search">
            <i className="fas fa-arrow-up" />
          </button>
          <form method="post" action="#">
            <div className="form-group">
              <input
                type="search"
                name="search-field"
                defaultValue=""
                placeholder="Search Here"
                required=""
              />
              <button type="submit">
                <i className="fa fa-search" />
              </button>
            </div>
          </form>
        </div>
        {/* scroll strat============  */}
        <div className="scroll-area">
          <div className="top-wrap">
            <div className="go-top-btn-wraper">
              <div className="go-top go-top-button">
                <i className="fa fa-angle-double-up" aria-hidden="true" />
                <i className="fa fa-angle-double-up" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
        {/* scroll end============  */}
      </>
    </div>
  );
};

export default Hero;
