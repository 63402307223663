import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadWeb3 } from "../../connectivity/api";
import { useraddress, useraddressinshort } from "../../Actions";
import Web3 from "web3";
import {
  contractAddress,
  abi,
  tokencontractAddress,
  tokenabi,
} from "../../connectivity/api";
import { API } from "../../Utils/Api";
import axios from "axios";

const Login = () => {
  // const myState = useSelector((state) => state.Usermatamaskaddress);
  const [wattleaddress, setwattleaddress] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pleaseconnectwallet = () => toast("please connect wallet!");
  const pleaseRegisteryourAccount = () =>
    toast("please Register your Account!");

  const aaa = async () => {
    try {
      let useradd = wattleaddress;
      let address = await loadWeb3();
      setwattleaddress(address);
      dispatch(useraddress(address.toString()));
      dispatch(
        useraddressinshort(address.slice(0, 3) + "..." + address.slice(38, 42))
      );
    } catch (error) {
      console.log("error1", error);
    }
  };

  const checkaddress = async (event) => {
    event.preventDefault();

    let ipAddress = await axios.get('https://api.ipify.org?format=json');
    ipAddress= ipAddress.data.ip;

    let response = await API.post("/User_Login", {
      Accoutaddress: wattleaddress,
      ipaddress: ipAddress
    });
    console.log("responseLogin-->", response);
    response = response.data.data;
    
    if (response.result === "Successfull") {
      dispatch(useraddress(response.useraddress.toString()));
      dispatch(useraddressinshort(response.useraddress.slice(0, 3) + "..." + response.useraddress.slice(38, 42)));

      sessionStorage.setItem("UserId", response.uid_output);
      sessionStorage.setItem("UserAddress", response.useraddress);
      navigate("/Dashboard");
    } else {
      pleaseRegisteryourAccount();
    }

    // if (!wattleaddress) {
    //   pleaseconnectwallet();
    //   console.log("asasasaswewew", wattleaddress);
    // } else {
    //   window.web3 = new Web3(window.ethereum);
    //   let contract = new window.web3.eth.Contract(abi, contractAddress);
    //   let { id } = await contract.methods.users(wattleaddress).call();
    //   if (id) {
    //     navigate("/Dashboard");
    //   } else {
    //     pleaseRegisteryourAccount();
    //   }
    // }
  };

  // const User_Login = async () => {
  //   try {
  //     let response = await API.post("/User_Login", {
  //       Accoutaddress: Accoutaddress ,
  //       ipaddress:1,
  //     }
  //   );
  //   console.log("User_Login API", response);

  //   } catch (error) {
  //     console.log("User_Login API Error", error);
  //   }
  // };

  useEffect(() => {
    sessionStorage.clear();
  }, []);


  // useEffect(() => {
  //   setwattleaddress(myState);
  //   console.log("wattleaddress,.,.,",wattleaddress)
  // }, []); // Only re-run the effect if count changes

  return (
    <div>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          {/* <center>
        <img src="assets/images/logo.png"style={{width:"100px"}}></img>
        </center> */}
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
                  <div className="card overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p>Sign in to continue to Phoenix Matrix.</p>
                          </div>
                        </div>
                        <div className="col-5 align-self-end">
                          <img
                            src="Dashboardassets/images/profile-img.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="p-2">
                        <form className="form-horizontal" action="">
                          <h5 className="text-black">
                            Access your personal account
                          </h5>
                          <h5
                            id="metamaskConnection"
                            style={{
                              color: "green",
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            {/* Tron wallet is locked */}
                            {wattleaddress !== "Connect to wallet"
                              ? `Connected: ${wattleaddress}`
                              : "Wallet is locked"}
                          </h5>
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={aaa}
                          >
                            Authorized Login
                          </button>
                          <h5 className="mt-3 text-black">
                            Remember to Authorize with the Correct Address.
                          </h5>
                          <h5 className="mt-3 text-black">View an Account</h5>
                          <div className="mb-3">
                            {/* <label for="username" class="form-label">Username</label> */}
                            <input
                              type="text"
                              className="form-control"
                              // id="username"
                              value={wattleaddress}
                              onChange={(e) => setwattleaddress(e.target.value)}
                              placeholder={wattleaddress}
                            />
                          </div>
                          <h5 className="mt-3 text-black">
                            Enter the account id or Eth address
                          </h5>
                          <div className="mt-3 d-grid">
                            {/* <Link to="/Dashboard"> */}
                            {/* {" "} */}
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              onClick={checkaddress}
                            >
                              Log In
                            </button>
                            {/* </Link> */}
                          </div>
                          <div className="mt-3 text-center">
                            <div>
                              <p className="text-black">
                                Don't have an account ?{" "}
                                <Link
                                  to="/Register"
                                  className="fw-medium text-primary"
                                >
                                  {" "}
                                  Register{" "}
                                </Link>{" "}
                              </p>
                              <p className="text-black">
                                Copyright © 2024 Phoenix.live. All Rights
                                Reserved.
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            <div className="col-md-7 block" style={{display:"none"}}>
              <div className="row justify-content-center">
                <div className="col-md-6 bre">
                  <div className="text-center">
                    <h1 className="text-white">Login to your personal account</h1>
                    <h5 className="text-white">
                      For access to all the functions of your personal account,
                      use automatic login
                    </h5>
                    <h5
                      id="metamaskConnection"
                      style={{
                        color: "green",
                        fontWeight: 600,
                        fontSize: 11,
                        overflow: "hidden",
                      }}
                    >
                      {/* Tron wallet is locked */}
                      {wattleaddress !== "Connect to wallet"
                        ? `Connected: ${wattleaddress}`
                        : "Wallet is locked"}
                    </h5>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={aaa}
                    >
                      Authorized Login
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3 text-center">
                    <h1 className="text-white">
                      To view account<br></br> ID or<br></br>
                      wallet address
                    </h1>
                    {/* <label for="username" class="form-label">Username</label> */}
                    <input
                      type="text"
                      className="form-control tr"
                      // id="username"
                      value={wattleaddress}
                      placeholder={wattleaddress}
                      onChange={(e) => setwattleaddress(e.target.value)}
                    />
                  </div>
                  <div className="mt-3 d-grid">
                    {/* <Link to="/Dashboard"> */}
                    {/* {" "} */}
                    <button
                      className="bor btn btn-primary waves-effect waves-light"
                      type="submit"
                      onClick={checkaddress}
                    >
                      Log In
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
