import React, { useState, useEffect } from "react";
import usdt from "../../assets/usdt.png";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { GiRecycle } from "react-icons/gi";
import { FaArrowUpLong } from "react-icons/fa6";
import axios from "axios";
import { loadWeb3 } from "../../connectivity/api";
import {
  contractAddress,
  abi,
  tokencontractAddress,
  tokenabi,
} from "../../connectivity/api";
import { ToastContainer, toast } from "react-toastify";
import { API } from "../../Utils/Api";

const Slot2 = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0; //myState.address
  let myteam = useSelector((state) => state.totalteamsize);
  let thisslotincome = useSelector((state) => state.totalrevnew);
  let User_MainID = useSelector((state) => state.MainID);
  const [userteam, setuserteam] = useState(0);
  const [renewslot1, setrenewslot1] = useState(0);
  // const [addresses, setAddresses] = useState([]);
  const [usertotalrenew, setusertotalrenew] = useState("0");
  const [info1, setinfo1] = useState([]);
  const [allcontrol, setallcontrol] = useState(0);
  const [recycleIDs, setRecycleIDs] = useState([]);
  const [btnDisable, setbtnDisable] = useState(false);
  const [treeData, settreeData] = useState();

  const [addresses, setAddresses] = useState({
    id: "",
    parent: "",
    levelOne: [],
    levelTwo: [],
  });

  let slot = 2;
  let slotAmount = 10;

  //console.log("LoginAddress====>", LoginAddress);
  // console.log("addresses==>", addresses);

  const userinfo = async () => {
    try {
      console.log("User_MainID", User_MainID, LoginAddress);
      let intex1;
      let totalslotrevenue;
      let a;
      let aa;
      let formattedAddresses = [];

      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        console.log("users LoginAddress ==>", LoginAddress);
        let { id } = await contract.methods.users(LoginAddress).call();
        let userdata = await contract.methods.users(LoginAddress).call();

        console.log("users==>", userdata);
        const {
          0: addressZero,
          1: arrayOne,
          2: arrayTwo,
          3: boolThree,
          4: addressFour,
        } = await contract.methods.usersa2Matrix(LoginAddress, slot).call();
        setuserteam(parseInt(arrayOne.length));

        // console.log("arrayOne", arrayOne);

        let arrayOneArray = [];
        for (let item of arrayOne) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayOneArray.push(id);
        }

        let arrayTwoArray = [];
        for (let item of arrayTwo) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayTwoArray.push(id);
        }

        let treeid = id === undefined ? "" : parseInt(id);

        setAddresses({
          id: treeid,
          parent:
            addressZero === "0x0000000000000000000000000000000000000000"
              ? ""
              : addressZero,
          levelOne: arrayOneArray,
          levelTwo: arrayTwoArray,
        });

        const { 0: recycleId, 1: recyclePosition_ } = await contract.methods
          .getPosition(LoginAddress, slot)
          .call();
        setrenewslot1(parseInt(recycleId));
        // console.log(recycleId, "recycleId: ");
      }
    } catch (error) {
      console.log("Tree_View_Child Error :", error);
    }
  };

  useEffect(() => {
    // userinfo();
    bindTreeHistory();
    bindTree_New();
  }, []);

  const [recycleCount, setrecycleCount] = useState(0);
  const bindtreeData_Prev = async () => {
    try {
      let uid = 0;
      if (treeData !== undefined) {
        let treelength = treeData.treePreData.length;
        if (treelength > (recycleCount + 1)) {
          let A = treeData.treePreData;
          uid = treeData.treePreData[recycleCount + 1].newuid;
          setrecycleCount(recycleCount + 1);
        }
        else {
          let A = treeData.treePreData;
          uid = treeData.treePreData[recycleCount].newuid;
        }
      }
      let response = await API.post("/GetUserTreeData_Previous", {
        uid: uid,
        package: slot,
      });
      response = response.data.data;
      settreeData(response);
    } catch (error) {
      console.log("Error while in bindTree_New ", error);
    }
  };
  const bindtreeData_Next = async () => {
    try {
      let uid = 0;
      if (treeData !== undefined) {
        if (recycleCount > 1) {
          uid = treeData.treePreData[recycleCount - 1].newuid;
          setrecycleCount(recycleCount - 1);
          let response = await API.post("/GetUserTreeData_Previous", {
            uid: uid,
            package: slot,
          });
          response = response.data.data;
          settreeData(response);
        }
        else {
          uid = LoginId;
          setrecycleCount(0);

          let response = await API.post("/GetUserTreeData_Latest", {
            uid: parseInt(LoginId),
            package: slot,
          });
          response = response.data.data;
          settreeData(response);
        }        
      }
    } catch (error) {
      console.log("Error while in bindTree_New ", error);
    }
  };

  const bindTreeHistory = async () => {
    try {
      let response = await API.post("/GetTreeDataReport_New", {
        userAddress: LoginAddress,
        SlotAmount: 0,
        MatrixType: "A2",
      });
      response = response.data.data;
      // console.log("response",response);
      setinfo1(response);
    } catch (error) { }
  };

  const indexxxss = async (hgqku) => {
    window.web3 = new Web3(window.ethereum);
    let contract = new window.web3.eth.Contract(abi, contractAddress);
    if (hgqku == 0) {
      let a = await contract.methods.users(LoginAddress).call();
      // let indexxessid = await contract.methods
      //   .check_useractive_id_List(LoginAddress, 1)
      //   .call();
      // userinfo(indexxessid[parseInt(a.index)]);
    } else {
      setallcontrol(hgqku);
      let a = await contract.methods.users(LoginAddress).call();
      // let indexxessid = await contract.methods
      //   .check_useractive_id_List(LoginAddress, 1)
      //   .call();
      // if (indexxessid.length > 1) {
      //   userinfo(
      //     parseInt(indexxessid[parseInt(a.index) - parseInt(allcontrol)])
      //   );
      // } else {
      //   userinfo(parseInt(indexxessid[parseInt(a.index)]));
      // }
    }
  };

  const indexxxss_next = async () => {
    window.web3 = new Web3(window.ethereum);
    let contract = new window.web3.eth.Contract(abi, contractAddress);
    let a = await contract.methods.users(LoginAddress).call();
    // let indexxessid = await contract.methods
    //   .check_useractive_id_List(LoginAddress, 1)
    //   .call();
    // userinfo(parseInt(indexxessid[parseInt(a.index)]));
  };

  const buyNewLevel = async () => {
    try {
      setbtnDisable(true);
      if (LoginAddress) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(abi, contractAddress);
        const tokenContract = new web3.eth.Contract(
          tokenabi,
          tokencontractAddress
        );

        // Verify contract initialization
        if (!contract || !tokenContract) {
          throw new Error("Contract or token contract initialization failed");
        }
        let address = await loadWeb3();
        if (address.toUpperCase() != LoginAddress.toUpperCase()) {
          toast.error(`Address doesnot Macthed !`);
          setbtnDisable(false);
          return;
        }
        const user = await contract.methods.users(address).call();
        const id = user.id;
        const balanceOf = await tokenContract.methods.balanceOf(address).call();
        const oneEtherInWei = web3.utils.toWei(slotAmount.toString(), "ether");

        const isUserExists = await contract.methods
          .isUserExists(address)
          .call();
        if (parseInt(id) != 0) {
          if (isUserExists == true) {
            // Check if the user is not registered
            if (balanceOf >= oneEtherInWei) {
              await tokenContract.methods
                .approve(contractAddress, oneEtherInWei)
                .send({ from: address });

              toast.success("Token has been approved");

              let txnDataSlot = await contract.methods
                .buyNewLevel(1, slot)
                .send({ from: address, value: 0 });
              toast.success("Buy New Level has been successfully purchased");
              console.log(
                "resData transactionHash",
                txnDataSlot.transactionHash
              );
              txnDataSlot = txnDataSlot.transactionHash;

              /// Buy new Packege API call
              let userAddress = address;
              let userData = await contract.methods.users(userAddress).call();
              let userid = userData.id;

              // let uplineresult = await contract.methods.activeGoldInfos(userAddress, 1).call();
              // let position = parseInt(uplineresult.position);
              // let uplineId = parseInt(uplineresult.currentParent);
              // let uplineAddress = await contract.methods.idToAddress(uplineId).call();

              let resultSponsor = await API.post("/GetsponsorId", {
                useraddress: userAddress,
              });
              console.log("resultSponsor", resultSponsor);
              let sponsorId = resultSponsor.data.data[0][0].uplineId;
              let sponsorAddress = resultSponsor.data.data[0][0].uplineAddress;

              let res = await API.post("/InsertAlllUsers", {
                uid: parseInt(userid),
                sid: parseInt(sponsorId),
                refAddress: sponsorAddress,
                userAddress: userAddress,
                uplineId: parseInt(sponsorId), //parseInt(uplineId),
                uplineAddress: sponsorAddress, //uplineAddress,
                position: 1, // position,
                package: slot,
                type: "A2",
                txn: txnDataSlot,
              });

              console.log("Registartion API", res.data.data);
            } else {
              toast.error("Insufficiant Balance!!");
              setbtnDisable(false);
            }
          } else {
            toast.error("User does not eligible for Buy Level");
            setbtnDisable(false);
          }
        } else {
          toast.error("User is not registered");
          setbtnDisable(false);
        }
      } else {
        toast.error("Session expired ! Please login again");
        window.location.href = "/Login";
      }
    } catch (error) {
      console.log("Buy Level Error:", error);
    }
  };

  const bindTree_New = async () => {
    try {
      let response = await API.post("/GetUserTreeData_Latest", {
        uid: parseInt(LoginId),
        package: slot,
      });
      response = response.data.data;
      console.log("bindTree_New", response);
      settreeData(response);

    } catch (error) { }
  };

  const [dashData, setdashData] = useState([]);
  const Dashboard_API = async () => {
    try {
      let response = await API.post("/user_details", {
        UserId: LoginId,
      });
      response = response.data.data[0][0];
      setdashData(response);
    } catch (error) {
      console.log("Error while in dashboard", error);
    }
  };
  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <div>
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="row">
            <div className="contentt">
              <span>Slot {slot} </span>
            </div>
          </div>
          <div className="row pb-1">
            <div className="pb-2">
              <div className=" pb-3">
                <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                  <div className="row">
                    <div className="PDev">
                      <p style={{ color: "#000" }}>Slot2</p>
                      <div>
                        <img src={usdt} alt="usdt" className="usdtCoin" />
                        <b style={{ color: "#000" }}>{slotAmount}</b>
                      </div>
                    </div>
                  </div>
                  <div className="TreeImgMian ">
                    <ul className="tree vertical">
                      <li className="kzsxjka">
                        <div className="popover__wrapper">
                          <a>
                            <h2 className="popover__title">
                              <div
                                _ngcontent-cgm-c25=""
                                className={
                                  treeData !== undefined
                                    ? treeData.treeData[0].uid
                                    : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                }
                              >
                                {treeData !== undefined ? treeData.treeData[0].uid : ""}
                              </div>
                            </h2>
                          </a>
                        </div>
                        <ul>
                          <li>
                            <div className="popover__wrapper">
                              <a>
                                <h2 className="popover__title">

                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      treeData !== undefined
                                        ? treeData.treeData[1].uid
                                        : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {treeData !== undefined ? treeData.treeData[1].uid : ""}
                                  </div>
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">

                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[4].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[4].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>

                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[5].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[5].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[6].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[6].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <div className="popover__wrapper">
                              <a>
                                <h2 className="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      treeData !== undefined
                                        ? treeData.treeData[2].uid
                                        : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {treeData !== undefined ? treeData.treeData[2].uid : ""}
                                  </div>
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[7].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[7].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>

                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[8].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[8].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[9].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[9].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <div className="popover__wrapper">
                              <a>
                                <h2 className="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      treeData !== undefined
                                        ? treeData.treeData[3].uid
                                        : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {treeData !== undefined ? treeData.treeData[3].uid : ""}
                                  </div>
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[10].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[10].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>

                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[11].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[11].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="popover__wrapper">
                                  <a>
                                    <h2 className="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          treeData !== undefined
                                            ? treeData.treeData[12].uid
                                            : ""
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {treeData !== undefined ? treeData.treeData[12].uid : ""}
                                      </div>
                                    </h2>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="PDev">
                      <div>
                        <svg
                          _ngcontent-onl-c25=""
                          viewBox="0 0 16 16"
                          fill="none"
                          stroke="#0000"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 stroke-current text-white-500"
                        >
                          <path
                            _ngcontent-onl-c25=""
                            d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                            stroke-width="1.333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <b>{treeData !== undefined
                          ? treeData.cycleMember[0].total_member
                          : 0}</b>
                      </div>
                      <div>
                        {treeData !== undefined
                          ? treeData.cycleMember[0].totalcycle
                          : 0}
                        <svg
                          _ngcontent-onl-c25=""
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="#2CFF4E"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 stroke-current text-white-500"
                        >
                          <path
                            _ngcontent-onl-c25=""
                            clip-rule="evenodd"
                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row akhriPutti lksnflf">
            <div className="kdsjflke">
              <Link to="/Dashboard/Slot1">
                <span>
                  <GrLinkPrevious /> Prev
                </span>
              </Link>
              <span style={{ color: "#000" }}>Slot : {slot} </span>
              <Link to="/Dashboard/Slot3">
                <span>
                  Next <GrLinkNext />
                </span>
              </Link>
            </div>
          </div>
          <div className="row akhriPutti lksnflf mt-4">
          <div className="kdsjflke">
              <span
                style={{ color: "#000" }}
                onClick={() => bindtreeData_Prev()}
              >
                <GrLinkPrevious /> Prev
              </span>
              <span>Recycle</span>
              <span
                style={{ color: "#000" }}
                onClick={() => bindtreeData_Next()}
              >
                Next <GrLinkNext />
              </span>
            </div>
          </div>
          <div className="row akhriPutti mt-4">
            <div className="kdsjflkerre">
            {dashData.nextSlotA2 <= slot ? (
                    <>
                      <button
                        className="btn btn-primary waves-effect waves-light pre btn-sm"
                        onClick={() => buyNewLevel()}
                        disabled={btnDisable}
                      >
                        Buy Slot
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive lksnflf">
        <table className="table align-middle table-nowrap mb-0">
          <thead className="table-light tabelqq">
            <tr>
              <th>S.No</th>
              <th>Amount</th>
              <th>Time</th>
              {/* <th >Slot</th> */}
              <th>Upline ID</th>
              <th>Matrix ID</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {info1.map((user, index) => (
              <tr key={index}>
                <td className="align-middle">{index + 1}</td>
                <td className="align-middle">{user.amount}</td>
                <td className="align-middle">{user.edate}</td>
                {/* <td className="align-middle"> </td> */}
                <td className="align-middle">{user.fromid} </td>
                <td className="align-middle"> {user.uid} </td>
                <td className="align-middle"> {user.fromAddress.slice(0, 4) + "..." + user.fromAddress.slice(38, 42)} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Slot2;
