import React from 'react'

function Income2() {
  return (
    <div>
    <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card partnrPdd">
                        <div class="card-body">
                            <h4 class="card-title colow mb-4">Income 2</h4>
                            <div class="table-responsive">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                    <tr>
                                        <th class="align-middle">S.No</th>
                                        <th class="align-middle">Amount</th>
                                        <th class="align-middle">Time</th>
                                        <th class="align-middle">Slot</th>
                                        <th class="align-middle">Upline ID</th>
                                        <th class="align-middle">Matrix ID</th>
                                        <th class="align-middle">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colspan="7" class="colow">Loading...</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pagination-buttons" style="display: flex; justify-content: space-between; margin-top: 20px;"><button class="colow" disabled="">Previous</button><button class="colow" disabled="">Next</button></div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </div>
  )
}

export default Income2